// @ts-ignore - server-shared isn't fully typed yet
import { maskCredential } from '@readme/server-shared/metrics/mask-credential'; // eslint-disable-line readme-internal/no-restricted-imports
import { compareDesc } from 'date-fns';
import { useMemo } from 'react';

import { useReadmeApiRealtime } from '@core/hooks/useReadmeApi';
import { useReferenceStore } from '@core/store';
import type { APIKeyTrendsResponse, Group, LastUsedResponse } from '@core/types/metrics';

interface APIKeyTableDataItem {
  apiKey: string;
  dataSet?: {
    data: Record<string, number[]>;
  };
  hashedKey: string;
  labels?: string[];
  lastUsed?: string | undefined;
  name: number | string;
}

/**
 * Returns list of API Keys sorted by last used data for usage in Realtime pages
 */
const useAPIKeys = ({ fetchTrendData = false }: { fetchTrendData?: boolean } = {}) => {
  const groups = useReferenceStore(s => s.auth.groups);

  const parsedKeys = useMemo(() => {
    return groups?.length
      ? groups.map((key: Group) => ({
          apiKey: key.id || '',
          // Add masked key to the data so we can use it in the UI
          hashedKey: maskCredential(key.id),
          name: key.name,
        }))
      : [];
  }, [groups]);

  const { lastUsedPath, keyTrendPath } = useMemo(() => {
    if (!parsedKeys?.length) {
      return {
        lastUsedPath: '',
        keyTrendPath: '',
      };
    }

    // Build query params for keys and skip including any keys that don't have a hashedKey
    const query = new URLSearchParams(
      parsedKeys?.filter(({ hashedKey }) => !!hashedKey).map(({ hashedKey }) => ['keys', hashedKey]),
    );

    return {
      lastUsedPath: `api/realtime/last-used?${query}`,
      // Note: we want 4 buckets for the graph, so we use a rangeLength of 4 weeks
      keyTrendPath: `api/realtime/apikey-trends?rangeLength=4&resolution=week&${query}`,
    };
  }, [parsedKeys]);

  const { data: keyTrendData } = useReadmeApiRealtime<APIKeyTrendsResponse>(
    keyTrendPath,
    {},
    fetchTrendData && !!keyTrendPath.length,
  );

  const { data: lastUsedData } = useReadmeApiRealtime<LastUsedResponse[]>(lastUsedPath, {}, !!lastUsedPath.length);

  const keysSortedByLastUsed = useMemo<APIKeyTableDataItem[]>(() => {
    if (!lastUsedData?.length) return parsedKeys;

    // Add in and sort by last used data
    return parsedKeys
      .map(dd => {
        const { lastUsed } = lastUsedData?.find(({ apiKey }) => apiKey === dd.hashedKey) || {};

        // Match up the last used data with the key trend data (when it becomes available)
        const { dataSet, labels } = keyTrendData?.[dd.hashedKey] || {};

        return { ...dd, lastUsed, dataSet, labels };
      })
      .sort((a, b) => {
        // both values are falsy, consider them equal
        if (!a.lastUsed && !b.lastUsed) {
          return 0;
        }

        // both values are falsy, consider them equal
        if (!a.lastUsed) {
          return 1;
        }

        // b.lastUsed is falsy, move it to the end
        if (!b.lastUsed) {
          return -1;
        }

        return compareDesc(new Date(a.lastUsed), new Date(b.lastUsed));
      });
  }, [keyTrendData, lastUsedData, parsedKeys]);

  return {
    keysSortedByLastUsed,
  };
};

export default useAPIKeys;
