import type { $TSFixMe } from '@readme/iso';

import React, { useCallback, useState, useEffect, useMemo } from 'react';

import classy from '@core/utils/classy';
import { cleanupExternalTooltip, renderExternalTooltip } from '@core/utils/graphTooltip';
import obfuscateKey from '@core/utils/obfuscateKey';
import shortUrl from '@core/utils/shortUrl';

import Badge from '@ui/Badge';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import LineGraph from '@ui/LineGraph';
import Skeleton from '@ui/Skeleton';
import Tooltip from '@ui/Tooltip';

import { EMPTY_VALUE } from './constants';
import GroupFilters from './GroupFilters';
import MetricsGraphTooltip from './MetricsGraphTooltip';
import parseData from './parseData';
import parseOptions from './parseOptions';
import classes from './style.module.scss';

const DEFAULT_TO_SHOW = 5;

interface Props {
  /** Override options for setAppearance in parseData */
  appearanceOptions?: $TSFixMe;
  aspectRatio?: number;
  baseUrl?: string;
  className?: string;
  currentDateRange?: string;
  data: $TSFixMe;
  fallback?: React.ReactNode;
  footerContent?: React.ReactNode;
  groupFilterName?: string;
  heading?: React.ReactNode;
  hideAxis?: boolean;
  isCompare?: boolean;
  isCondensed?: boolean;
  isDevData?: boolean;
  isDisabled?: boolean;
  isInteractive?: boolean;
  isMissingData?: boolean;
  isStacked?: boolean;
  menu?: React.ReactNode;
  onGroupFilterChange?: (groupFilterName: string, groupFilters: string[], isAllSelected: boolean) => void;
  primaryAxisScale?: 'interval' | 'ordinal';
  shouldRenderFallback?: boolean;
  sortGroupsBySum?: boolean;
  subdomain?: string;
  subheading?: React.ReactNode;
  theme?: 'default' | 'empty' | 'page-quality' | 'status-code-groups' | 'status-codes';
  title?: string;
  /** Custom class name to pass into tooltip items */
  tooltipClassName?: string;
  type?: 'bar' | 'line';
  version?: string;
}

const MetricsGraph = ({
  aspectRatio,
  baseUrl = '',
  appearanceOptions,
  className,
  currentDateRange = '',
  data: providedData,
  fallback,
  footerContent,
  groupFilterName = '',
  heading,
  hideAxis = false,
  isCompare = false,
  isCondensed = false,
  isDisabled = false,
  isDevData = false,
  isMissingData = false,
  isStacked = false,
  isInteractive = true,
  onGroupFilterChange,
  menu,
  primaryAxisScale = 'interval',
  shouldRenderFallback = false,
  sortGroupsBySum = true,
  subdomain = '',
  subheading,
  theme = 'default',
  title = '',
  tooltipClassName,
  type = 'line',
  version = '',
}: Props) => {
  const parsedData = useMemo(
    () => parseData({ providedData, type, isStacked, theme, sortGroupsBySum, appearanceOptions }),
    [appearanceOptions, isStacked, providedData, sortGroupsBySum, theme, type],
  );

  const isGrouped = useMemo(
    () => primaryAxisScale === 'ordinal' || parsedData.isGrouped,
    [primaryAxisScale, parsedData.isGrouped],
  );

  const isGroupedBarChart = useMemo(() => isGrouped && type === 'bar', [isGrouped, type]);

  // Check/select the first 5 group filters/facets or labels by default
  const defaultGroupFilters = useCallback(() => {
    if (isGroupedBarChart) return parsedData.labels.slice(0, DEFAULT_TO_SHOW);

    return parsedData.groups.map(group => group.name).slice(0, DEFAULT_TO_SHOW);
  }, [isGroupedBarChart, parsedData]);

  // Actively selected group filters/facets for the graph and tooltips
  const [groupFilters, updateGroupFilters] = useState<string[]>(defaultGroupFilters());
  const [displayData, updateDisplayData] = useState({ datasets: parsedData.datasets, labels: parsedData.labels });

  // Labels aren't provided for every point in condensed graphs so tooltips look strange for datapoints that aren't either the first or last one
  const tooltip = renderExternalTooltip(MetricsGraphTooltip, {
    data: displayData,
    title,
    isCompare,
    className: tooltipClassName,
    theme,
    currentDateRange,
    groupFilterName,
  });

  const activeGroupFiltersCount = groupFilters.length;

  // Format labels for the Y axis of the graph to handle API key obfuscation and empty values
  const formatScaleYLabels = useCallback(
    (labelValue: string | null) => {
      if (isGroupedBarChart && groupFilterName === 'Group ID') {
        return labelValue ? obfuscateKey(labelValue, 'before', 4) : EMPTY_VALUE;
      }

      return labelValue || EMPTY_VALUE;
    },
    [isGroupedBarChart, groupFilterName],
  );

  const options = useMemo(
    () =>
      parseOptions({
        type,
        isCondensed,
        isGrouped,
        groupsCount: activeGroupFiltersCount,
        isStacked,
        tooltip,
        hideAxis,
        aspectRatio,
        isInteractive,
        scaleYTicksCallback: formatScaleYLabels,
      }),
    [
      type,
      isCondensed,
      isGrouped,
      activeGroupFiltersCount,
      isStacked,
      tooltip,
      hideAxis,
      aspectRatio,
      isInteractive,
      formatScaleYLabels,
    ],
  );

  // reset the group filter selection to 5 when the graph changes
  useEffect(() => {
    updateGroupFilters(defaultGroupFilters());
  }, [providedData, defaultGroupFilters]);

  useEffect(() => {
    // the shape of grouped bar chart data is different from everything else
    if (isGroupedBarChart) {
      const targetIndexes = groupFilters.map(filter => parsedData.labels.indexOf(filter));

      updateDisplayData({
        datasets: parsedData.datasets.map(dataset => {
          return isStacked
            ? {
                ...dataset,
                data: dataset.data.filter((_: $TSFixMe, i: number) => targetIndexes.includes(i)),
              }
            : {
                ...dataset,
                backgroundColor: dataset.backgroundColor.filter((_: string, i: number) => targetIndexes.includes(i)),
                barThickness: 10,
                borderRadius: 2,
                hoverBackgroundColor: dataset.hoverBackgroundColor.filter((_: string, i: number) =>
                  targetIndexes.includes(i),
                ),
                hoverBorderColor: dataset.backgroundColor.filter((_: string, i: number) => targetIndexes.includes(i)),
                data: dataset.data.filter((_: $TSFixMe, i: number) => targetIndexes.includes(i)),
              };
        }),
        labels: parsedData.labels.filter((d: string, i: number) => targetIndexes.includes(i)),
      });
    } else {
      updateDisplayData({
        datasets: isGrouped
          ? parsedData.datasets.filter(dataset => groupFilters.includes(dataset.group))
          : parsedData.datasets,
        labels: parsedData.labels,
      });
    }
  }, [isGroupedBarChart, parsedData, groupFilters, isStacked, type, isGrouped]);

  useEffect(() => {
    // clear group filters when graph is no longer grouped
    if (!isGrouped && groupFilters.length) {
      updateGroupFilters([]);
    }
  }, [groupFilters.length, isGrouped]);

  useEffect(() => {
    return () => {
      // Cleanup any tooltip div elements on unmount of MetricsGraph
      setTimeout(() => {
        cleanupExternalTooltip();
      }, 1000);
    };
  }, []);

  const handleUpdateGroupFilters = useCallback(
    (filters, isAllSelected) => {
      updateGroupFilters(filters);

      // Update options.table.query filters for /list fetch
      onGroupFilterChange?.(groupFilterName, filters, isAllSelected);
    },
    [updateGroupFilters, onGroupFilterChange, groupFilterName],
  );

  const groupLabels = useMemo(() => {
    if (isGroupedBarChart && theme === 'page-quality') {
      return parsedData.labels.map((name: string, i: number) => {
        const downvoteCount = parsedData.datasets[0]?.data?.[i] ?? 0;
        const upvoteCount = parsedData.datasets[1]?.data?.[i] ?? 0;
        const total = Math.abs(downvoteCount) + Math.abs(upvoteCount);

        return {
          name,
          total,
        };
      });
    }

    if (primaryAxisScale === 'ordinal') {
      return parsedData.labels.map((label: string) => {
        return { name: label };
      });
    }

    if (groupFilterName === 'URL') {
      // Attempt to format labels that are URLs
      return parsedData.groups.map(group => {
        return { ...group, url: shortUrl(group.name) };
      });
    }

    return parsedData.groups;
  }, [parsedData, primaryAxisScale, groupFilterName, isGroupedBarChart, theme]);

  // Group filters are checkbox filtering for charts (specific to /metrics pages)
  const showGroupFilters = !shouldRenderFallback && !isCondensed && !!isGrouped && Boolean(groupLabels.length);
  const showBadge = isMissingData || isDevData;

  return (
    <section
      className={classy(
        classes.MetricsGraph,
        isCondensed && classes.MetricsGraph_condensed,
        isDisabled && classes.MetricsGraph_disabled,
        className,
      )}
    >
      <div className={classes['MetricsGraph-main-wrapper']}>
        <div className={classes['MetricsGraph-main']}>
          {!!heading && (
            <Flex align="start" className={classes['MetricsGraph-header']}>
              <div>
                <h2 className={classes['MetricsGraph-heading']}>{heading}</h2>
                {shouldRenderFallback ? (
                  theme !== 'page-quality' ? (
                    <Skeleton height="8px" width="50%" />
                  ) : null
                ) : (
                  <div className={classes['MetricsGraph-subheading']}>{subheading}</div>
                )}
              </div>
              {!!showBadge && !shouldRenderFallback && (
                <Badge className={classes['MetricsGraph-badge']} kind={isMissingData ? 'alert' : 'failure'}>
                  {isMissingData ? (
                    <Tooltip content="Showing your “Try It” data. Set up Metrics to get a fuller understanding of how customers use your API.">
                      <span>Try It Data</span>
                    </Tooltip>
                  ) : (
                    'Dev Data'
                  )}
                </Badge>
              )}
              {!showBadge && !!menu && (
                <Dropdown className={classes['MetricsGraph-dropdown']}>
                  <Button ghost kind="minimum" size="sm">
                    <i className="icon-more-vertical" />
                  </Button>
                  {menu}
                </Dropdown>
              )}
            </Flex>
          )}

          <section className={classes['MetricsGraph-chart']}>
            {shouldRenderFallback ? (
              fallback
            ) : (
              <LineGraph data={displayData} options={options} style={{ position: 'relative', width: '100%' }} />
            )}
          </section>
        </div>

        {!!showGroupFilters && (
          <GroupFilters
            baseUrl={baseUrl}
            groupFilterName={groupFilterName}
            groupFilters={groupFilters}
            groupLabels={groupLabels}
            isStacked={isStacked}
            subdomain={subdomain}
            title={title}
            updateGroupFilters={handleUpdateGroupFilters}
            version={version}
          />
        )}
      </div>
      {!!footerContent && <section className={classes['MetricsGraph-footer']}>{footerContent}</section>}
    </section>
  );
};

export { default as statusCategories } from './statusCategories';

export default MetricsGraph;
