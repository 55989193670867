import React from 'react';

import Flex from '@ui/Flex';
import Graphic from '@ui/Graphic';

interface Props {
  fallback?: React.ReactNode;
  /** Optional onError callback for logging to Sentry */
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

interface State {
  hasError: boolean;
}

/**
 * Generic ErrorBoundary class component that can be used to wrap any component and catch errors w/ fallback UI.
 *
 * Follows React guidelines: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 *
 * Note: There is currently no way to write an error boundary as a functional component unless we were to use a library like [react-error-boundary](https://github.com/bvaughn/react-error-boundary).
 */
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { onError } = this.props;

    onError?.(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    const fallback = this.props.fallback || (
      <Flex align="center" gap="xs" justify="center" layout="col">
        <Graphic name="warning" size="lg" />
        Something went wrong!
      </Flex>
    );

    return hasError ? fallback : children;
  }
}

export default ErrorBoundary;
