import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import ObfuscatedAPIKey from '@ui/ObfuscatedAPIKey';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

const AUTH_HEADER_KEY = 'authorization';

function decodeBase64(val) {
  try {
    return window.atob(val);
  } catch (err) {
    // If there's a value in the api key that is not a valid base64 character
    // then it throws, and returning `null` breaks the spread operator below
    // so lets just return a string here
    return '';
  }
}

const BasicHeaderReveal = ({ className, theme = 'light', header: { name, value } }) => {
  const isBasicAuth = name === AUTH_HEADER_KEY && value.includes('Basic');
  const encodedValue = isBasicAuth ? value.replace(/Basic /, '') : null;

  const [displayValue, setDisplayValue] = useState(encodedValue);
  const isEncoded = useMemo(() => displayValue === encodedValue, [displayValue, encodedValue]);
  const decodedValue = useMemo(() => decodeBase64(encodedValue), [encodedValue]);

  // For encrypted keys, we want to show obfuscated key instead
  const showObfuscatedKey = useMemo(() => {
    return value?.startsWith('sha512-') && value?.includes('?');
  }, [value]);

  // The ReadMe API technically accepts plain api keys in basic headers that aren’t base64 encoded.
  // If the decoded value does not use ASCII based character encoding, it may not be base64 encoded in the first place.
  // The first 0 to 127 of `char.charCodeAt(0)` are a direct match of the ASCII character set.
  const isDecodedValueAscii = ![...decodedValue].some(char => char.charCodeAt(0) > 127);

  const bem = useClassy(classes, 'BasicHeaderReveal');

  const handleToggle = () => {
    setDisplayValue(isEncoded ? decodedValue : encodedValue);
  };

  const renderToggle = () =>
    decodedValue && isDecodedValueAscii ? (
      <span className={bem('&', `_${theme}`, className)}>
        <Tooltip
          content={
            <Flex align="center" style={{ '--flex-gap': '1ch' }}>
              {isEncoded ? (
                <>
                  <i className="icon-eye-2" /> Decode Credentials
                </>
              ) : (
                <>
                  <i className="icon-eye-off" /> Re-Encode Credentials
                </>
              )}
            </Flex>
          }
          hideOnClick={false}
          placement="bottom"
        >
          <button aria-label="Toggle credentials" className={bem('-toggle')} onClick={handleToggle}>
            {displayValue}
          </button>
        </Tooltip>
      </span>
    ) : (
      // If value can't be decoded, just pass the encoded value back as a string
      encodedValue
    );

  return showObfuscatedKey ? (
    <ObfuscatedAPIKey allowCopy={false} allowExpansion={false} apiKey={value} conceal="before" displayLength={4} />
  ) : !isBasicAuth ? (
    value
  ) : (
    <>Basic {renderToggle()}</>
  );
};

BasicHeaderReveal.propTypes = {
  className: PropTypes.string,
  header: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default BasicHeaderReveal;
