import React, { useCallback, useMemo, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useRealtimeStore } from '@core/store/Realtime';
import type { DateRangeOptions } from '@core/types/metrics';

import { getSelectedDateRangeKey } from '@routes/Dash/Project/Metrics/Context';

import DateRange from '@ui/DateRange';

import classes from './style.module.scss';

interface Props {
  isDevDashEnabled: boolean;
}

const MyRequestsDateRange = ({ isDevDashEnabled }: Props) => {
  const bem = useClassy(classes, 'MyRequestsDateRange');

  // Get filters from Realtime global store
  const [filters, isReady, updateFilters] = useRealtimeStore(state => [
    state.filters,
    state.isReady,
    state.updateFilters,
  ]);

  const tooltipRef = useRef(null);

  const dateFilters = useMemo(() => {
    return {
      rangeEnd: filters.rangeEnd,
      rangeStart: filters.rangeStart,
      rangeLength: filters.rangeLength,
      resolution: filters.resolution,
    };
  }, [filters.rangeEnd, filters.rangeStart, filters.rangeLength, filters.resolution]);

  const handleRangeChange = useCallback(
    (key, range) => {
      updateFilters({
        rangeLength: range.rangeLength,
        resolution: range.resolution,
        rangeStart: undefined,
        rangeEnd: undefined,
      });
    },
    [updateFilters],
  );

  const onCustomRangeChange = useCallback(
    (key, range) => {
      updateFilters({
        rangeEnd: range.rangeEnd,
        rangeStart: range.rangeStart,
        rangeLength: range.rangeLength,
        resolution: range.resolution,
      });
    },
    [updateFilters],
  );

  const onRangeClick = (key, range) => {
    handleRangeChange(key, range);
  };

  const dateRangeDefaults = useMemo(() => {
    // Limit date ranges to last 24 hours if dev dash is not enabled
    const limitedDateRangeEnabled = !!isDevDashEnabled;

    return {
      day: {
        rangeEnd: null,
        rangeStart: null,
        rangeLength: 24,
        resolution: 'hour',
        enabled: true,
      },
      week: {
        rangeEnd: null,
        rangeStart: null,
        rangeLength: 7,
        resolution: 'day',
        enabled: limitedDateRangeEnabled,
      },
      month: {
        rangeEnd: null,
        rangeStart: null,
        rangeLength: 30,
        resolution: 'day',
        enabled: limitedDateRangeEnabled,
      },
      quarter: {
        rangeEnd: null,
        rangeStart: null,
        rangeLength: 12,
        resolution: 'week',
        enabled: limitedDateRangeEnabled,
      },
      year: {
        rangeEnd: null,
        rangeStart: null,
        rangeLength: 12,
        resolution: 'month',
        enabled: limitedDateRangeEnabled,
      },
    } as DateRangeOptions;
  }, [isDevDashEnabled]);

  const selectedDateRangeKey = useMemo(() => {
    return getSelectedDateRangeKey({
      dateRanges: dateRangeDefaults,
      rangeLength: Number(filters.rangeLength),
      resolution: filters.resolution,
    });
  }, [dateRangeDefaults, filters.rangeLength, filters.resolution]);

  if (!isReady) {
    return null;
  }

  return (
    <div className={bem('&')}>
      <DateRange
        ref={tooltipRef}
        dateRanges={dateRangeDefaults}
        filters={dateFilters}
        onCustomRangeChange={onCustomRangeChange}
        onCustomRangeClick={() => {}}
        onRangeClick={onRangeClick}
        selectedKey={selectedDateRangeKey}
      />
    </div>
  );
};

export default MyRequestsDateRange;
