import type { $TSFixMe } from '@readme/iso';
import type { Chart, ChartType, TooltipModel } from 'chart.js';

import throttle from 'lodash/throttle';
import React from 'react';
import ReactDOM from 'react-dom';

const CHARTJS_TOOLTIP_ID = 'chartjs-tooltip';

export type RenderExternalTooltipReturnType = (context: { chart: Chart; tooltip: TooltipModel<ChartType> }) => void;

type RenderExternalProps = Record<string, $TSFixMe>;

type Props = RenderExternalProps & {
  chart: Chart;
  tooltip: TooltipModel<ChartType>;
};

export function renderExternalTooltip(
  TooltipComponent: React.ComponentType<Props>,
  props?: RenderExternalProps,
): RenderExternalTooltipReturnType {
  return throttle(context => {
    // Chart and tooltip are passed in via chart.js rendered context
    const { chart, tooltip } = context;

    // Find or create tooltip DOM node
    let tooltipEl = document.querySelector(`#${CHARTJS_TOOLTIP_ID}`);
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.setAttribute('id', CHARTJS_TOOLTIP_ID);
      document.body.appendChild(tooltipEl);
    }

    // Whenever chart.js invokes this callback, the tooltip component will be rendered to the DOM
    ReactDOM.render(<TooltipComponent {...props} chart={chart} tooltip={tooltip} />, tooltipEl);
  }, 100);
}

export function cleanupExternalTooltip() {
  const tooltipEl = document.querySelector(`#${CHARTJS_TOOLTIP_ID}`);
  if (tooltipEl) {
    ReactDOM.unmountComponentAtNode(tooltipEl);
    tooltipEl.remove();
  }
}
