import React, { useCallback, useMemo, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import type { MetricsFilterType, RecentRequestFilters, MetricsFilter } from '@core/types/metrics';

import Filter from '../Filter';
import MoreDropdown from '../MoreDropdown';
import styles from '../style.module.scss';

const DEFAULT_VISIBLE_FILTER_COUNT = 5;

interface Props {
  /** Currently active (selected) filters (from client) */
  activeFilters: RecentRequestFilters;
  /** Active filters data (from server) */
  activeFiltersData: MetricsFilter['data'];
  /** All available filters data for the given type (from server) */
  allFiltersData: MetricsFilter['data'];
  /** Clear filter type callback */
  clearFilterType: (filterType: MetricsFilterType) => void;
  /** The filter type (based on Column enum) */
  filterType: MetricsFilterType;
  /** Whether filter has changed at all from initial filters */
  hasChangedFromInitialFilters: boolean;
  /** Display name for filter group */
  name: string;
  /** Set filter callback */
  setFilter: (key: MetricsFilterType, value: string) => void;
  /** Unset filter callback */
  unsetFilter: (key: MetricsFilterType, value: string) => void;
}

const FilterGroup = ({
  activeFilters,
  activeFiltersData,
  allFiltersData,
  clearFilterType,
  filterType,
  hasChangedFromInitialFilters,
  name,
  setFilter,
  unsetFilter,
}: Props) => {
  const bem = useClassy(styles, 'FilterGroup');

  const [moreSearch, setMoreSearch] = useState('');

  const updateMoreSearch = useDebounced((value: string) => {
    setMoreSearch(value);
  }, 300);

  const handleChange = useCallback(
    value => {
      updateMoreSearch(value);
    },
    [updateMoreSearch],
  );

  const activeFiltersForType = useMemo(() => {
    return activeFilters[filterType] || [];
  }, [activeFilters, filterType]) as string[];

  // Valid filters are filters returned from /filters that we can still filter down on (i.e. they have counts)
  const validFiltersMap = useMemo(() => {
    const map = new Map();

    if (!hasChangedFromInitialFilters) return map;

    if (activeFiltersData) {
      activeFiltersData.forEach(f => map.set(f.name, f.count));
    }

    return map;
  }, [activeFiltersData, hasChangedFromInitialFilters]);

  // Selected filters are filters that are currently selected (checked)
  const selectedFiltersMap = useMemo(() => {
    const map = new Map();

    if (!hasChangedFromInitialFilters) return map;

    if (activeFiltersForType) {
      activeFiltersForType.forEach(f => map.set(f, true));
    }

    return map;
  }, [activeFiltersForType, hasChangedFromInitialFilters]);

  // Returns all filters with valid + selected shown first, sorted by count
  const filters = useMemo(() => {
    if (!hasChangedFromInitialFilters) return allFiltersData;

    const onlyActiveFilters: MetricsFilter['data'] = [];
    const allOtherFilters: MetricsFilter['data'] = [];

    allFiltersData.forEach(filter => {
      // If the filter is valid or selected, show it first
      const filterCount = validFiltersMap.get(filter.name) || selectedFiltersMap.get(filter.name);

      if (filterCount) {
        onlyActiveFilters.push({ ...filter, count: typeof filterCount === 'number' ? filterCount : 0 });
      } else {
        allOtherFilters.push({ ...filter, count: 0 });
      }
    });

    return onlyActiveFilters.sort((a, b) => b.count - a.count).concat(allOtherFilters);
  }, [allFiltersData, hasChangedFromInitialFilters, selectedFiltersMap, validFiltersMap]);

  const numberOfFiltersToShow = useMemo(() => {
    return activeFiltersForType && activeFiltersForType?.length > DEFAULT_VISIBLE_FILTER_COUNT
      ? activeFiltersForType?.length
      : DEFAULT_VISIBLE_FILTER_COUNT;
  }, [activeFiltersForType]);

  // Visible filters are the filters that are shown in the group before More...
  const visibleFilters = useMemo(() => {
    if (!hasChangedFromInitialFilters) {
      return allFiltersData.slice(0, DEFAULT_VISIBLE_FILTER_COUNT);
    }

    return filters.slice(0, numberOfFiltersToShow);
  }, [allFiltersData, filters, hasChangedFromInitialFilters, numberOfFiltersToShow]);

  // More filters are the filters that are shown in More... dropdown menu
  const moreFilters = useMemo(() => {
    const result = filters.slice(numberOfFiltersToShow, filters.length);

    if (moreSearch) {
      return result.filter(filter => {
        const n = filter.name.toLowerCase();
        return n.includes(moreSearch.toLowerCase());
      });
    }

    return result;
  }, [filters, moreSearch, numberOfFiltersToShow]);

  // Count to show for More... menu
  const moreCount = useMemo(() => {
    return moreFilters.reduce((acc, filter) => acc + filter.count, 0);
  }, [moreFilters]);

  const showMoreDropdown = allFiltersData.length > DEFAULT_VISIBLE_FILTER_COUNT;
  const showResetButton = activeFiltersForType.length > 0;

  if (allFiltersData.length === 0) return null;

  return (
    <>
      <header className={bem('-header')}>{name}</header>
      <ol className={bem('-list')}>
        {visibleFilters.map(f => {
          const isChecked = selectedFiltersMap.has(f.name);
          const showCount = isChecked || f.count > 0;

          return (
            <li key={`filter-${f.name}`}>
              <Filter
                filter={f}
                filterType={filterType}
                isChecked={isChecked}
                setFilter={setFilter}
                showCount={showCount}
                unsetFilter={unsetFilter}
              />
            </li>
          );
        })}

        {!!showMoreDropdown && (
          <MoreDropdown
            clearFilterType={clearFilterType}
            clearSearch={() => setMoreSearch('')}
            count={moreCount}
            filters={moreFilters}
            filterType={filterType}
            moreSearch={moreSearch}
            name={name}
            onSearchChange={handleChange}
            selectedFiltersMap={selectedFiltersMap}
            setFilter={setFilter}
            showResetButton={showResetButton}
            unsetFilter={unsetFilter}
          />
        )}
      </ol>
    </>
  );
};

export default React.memo(FilterGroup);
