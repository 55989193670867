import type { RowData, Row, ColumnDef } from '@tanstack/react-table';

import React, { useState, useCallback, useMemo, useEffect } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useReadmeApiRealtime } from '@core/hooks/useReadmeApi';
import { useRealtimeStore } from '@core/store/Realtime';
import type { APIKeyRequestRow, RecentResponse } from '@core/types/metrics';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import ReactTable, { useTablePreferences } from '@ui/ReactTable';
import type { ColumnSort } from '@ui/ReactTable/types';

import classes from './style.module.scss';
import { defaultColumn, tablePrefs } from './tableModels';

interface Props {
  highlightRowOnClick?: boolean;
  isLoggedIn: boolean;
  onRowClick?: (row: Row<APIKeyRequestRow>) => void;
}

// @tanstack/react-table types available: packages/react/node_modules/@tanstack/table-core/build/types/index.d.ts
const MyRequestsTable = ({ highlightRowOnClick, isLoggedIn, onRowClick }: Props) => {
  const bem = useClassy(classes, 'MyRequestsTable');

  const [filters, getFiltersQueryString, getHasChangedFromInitialFilters, isReady, updateFilters] = useRealtimeStore(
    state => [
      state.filters,
      state.getFiltersQueryString,
      state.getHasChangedFromInitialFilters,
      state.isReady,
      state.updateFilters,
    ],
  );

  const filtersHaveChanged = getHasChangedFromInitialFilters();
  const queryParams = getFiltersQueryString();

  const { direction, page = 0, sort } = filters;

  const {
    columns,
    endpoint,
    sort: defaultSortColumn,
    direction: defaultSortDirection,
    order: defaultColumnOrder,
    visibility: defaultColumnVisibility,
    prefsName,
  } = tablePrefs;

  // Fetch table data from /realtime/recent
  const { data, error, isLoading, isValidating } = useReadmeApiRealtime<RecentResponse>(
    `${endpoint}?${queryParams}`,
    {},
    isLoggedIn && isReady,
  );

  const { columnOrder, setColumnOrder, columnVisibility, setColumnVisibility } = useTablePreferences({
    defaultOrder: defaultColumnOrder,
    defaultVisibility: defaultColumnVisibility,
    prefsName,
  });

  const [totalPages, setTotalPages] = useState(1);

  // Set pagination from response
  useEffect(() => {
    if (isLoading || !data?.logs?.length) return;

    setTotalPages(data?.pages || 1);
  }, [isLoading, data]);

  const updatePage = useCallback(
    selectedPage => {
      updateFilters({
        page: selectedPage,
      });
    },
    [updateFilters],
  );

  const onSortChange = useCallback(
    (newSort: ColumnSort) => {
      updateFilters({ sort: newSort.column, direction: newSort.direction });
    },
    [updateFilters],
  );

  const customEmptyRender = useMemo(() => {
    if (!isLoggedIn) {
      return (
        <Flex align="center" gap="xs">
          <Icon name="key" />
          <span>Log in to see request history</span>
        </Flex>
      );
    }

    return (
      <>
        No Requests
        {!!filtersHaveChanged && <span>Try updating your filters</span>}
      </>
    );
  }, [filtersHaveChanged, isLoggedIn]);

  return (
    <ReactTable
      className={bem('&')}
      columnOptions={{
        visibility: columnVisibility,
        order: columnOrder,
      }}
      columns={columns as ColumnDef<RowData>[]}
      customEmptyRender={customEmptyRender}
      data={data?.logs || []}
      defaultColumn={defaultColumn as Partial<ColumnDef<RowData>>}
      hasError={!!error}
      highlightRowOnClick={highlightRowOnClick}
      includeColumnSelector
      isCompact
      isLoading={isLoading || isValidating}
      layout="auto"
      onColumnOrderChange={setColumnOrder}
      onColumnVisibilityChange={setColumnVisibility}
      onPageChange={updatePage}
      onRowClick={onRowClick}
      onSort={onSortChange}
      page={page}
      pages={totalPages}
      sort={{
        column: sort || defaultSortColumn,
        direction: direction || defaultSortDirection,
      }}
      transparentHeader
    />
  );
};

export default MyRequestsTable;
