import React, { useMemo } from 'react';
import { VariableSizeList as VirtualList } from 'react-window';

import useClassy from '@core/hooks/useClassy';
import type { APIKey, Group } from '@core/types/metrics';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuItem, MenuHeader } from '@ui/Menu';
import ObfuscatedAPIKey from '@ui/ObfuscatedAPIKey';

import styles from './style.module.scss';

const VIRTUAL_LIST_ITEM_SIZE = 50;
const MENU_HEADER_HEIGHT = 25;

interface Props {
  activeAPIKey: string;
  /** Accepts API Keys in both { key, label } (Dash) and { id, name } (Hub) formats */
  apiKeys: APIKey[] | Group[];
  /** Whether to disable button */
  disabled?: boolean;
  menuHeader: string;
  onClick: (key: string) => void;
  showOutline?: boolean;
}

const APIKeyDropdown = ({ activeAPIKey, apiKeys, disabled, menuHeader, onClick, showOutline = false }: Props) => {
  const bem = useClassy(styles, 'APIKeyDropdown');

  const listHeight = useMemo(() => {
    if (!apiKeys?.length) return 0;
    return (
      (apiKeys.length >= 10 ? VIRTUAL_LIST_ITEM_SIZE * 10 : VIRTUAL_LIST_ITEM_SIZE * apiKeys.length) +
      MENU_HEADER_HEIGHT +
      5
    );
  }, [apiKeys]);

  // If there's only one key, we don't need to render the dropdown
  if (apiKeys.length <= 1) {
    return (
      <Flex align="center" className={bem('-one-key')} gap="xs" layout="row">
        <Icon color="gray70" name="key" size="md" strokeWeight={3} />
        {activeAPIKey ? (
          <ObfuscatedAPIKey
            allowCopy={false}
            allowExpansion={false}
            apiKey={activeAPIKey}
            conceal="before"
            displayLength={4}
          />
        ) : (
          'No API Key found'
        )}
      </Flex>
    );
  }

  return (
    <Dropdown align="top" appendTo={() => document.body} justify="start" sticky>
      <Button disabled={disabled} kind="secondary" outline={showOutline} size="sm" text={!showOutline}>
        <Icon color="gray70" name="key" strokeWeight={3} />
        <ObfuscatedAPIKey
          allowCopy={false}
          allowExpansion={false}
          apiKey={activeAPIKey}
          conceal="before"
          displayLength={4}
        />
        <Icon color="gray70" name="chevron-down" strokeWeight={3} />
      </Button>

      <Menu className={bem('-menu')}>
        <VirtualList
          height={listHeight}
          itemCount={apiKeys.length + 1}
          itemSize={i => (i === 0 ? MENU_HEADER_HEIGHT : VIRTUAL_LIST_ITEM_SIZE)}
          width="100%"
        >
          {({ index, style }) => {
            // We want to include MenuHeader in list, but separately from other elements
            if (index === 0) {
              return (
                <div style={style}>
                  <MenuHeader className={bem('-menuHeader')}>{menuHeader}</MenuHeader>
                </div>
              );
            }

            const dataIndex = index - 1;
            const isGroupFormat = 'id' in apiKeys[dataIndex];
            const key = isGroupFormat ? (apiKeys[dataIndex] as Group).id : (apiKeys[dataIndex] as APIKey).key;
            const label = String(
              isGroupFormat ? (apiKeys[dataIndex] as Group).name : (apiKeys[dataIndex] as APIKey).label,
            );
            const isActive = activeAPIKey === key;

            return (
              <div style={style}>
                <MenuItem active={isActive} onClick={() => onClick(key)}>
                  <Flex className={bem('-menuItem-key', isActive && '-menuItem-key_active')} gap="xs" layout="col">
                    <ObfuscatedAPIKey
                      allowCopy={false}
                      allowExpansion={false}
                      apiKey={key}
                      conceal="before"
                      displayLength={4}
                    />
                    <div className={bem('-menuItem-label')} title={label}>
                      {label}
                    </div>
                  </Flex>
                </MenuItem>
              </div>
            );
          }}
        </VirtualList>
      </Menu>
    </Dropdown>
  );
};

export default APIKeyDropdown;
