import { isEmpty } from 'lodash';
import React from 'react';

import useClassy from '@core/hooks/useClassy';
import type { APIRequestData } from '@core/types/metrics';
import abbreviatedNumber from '@core/utils/abbreviatedNumber';

import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Sparkline from '@ui/Sparkline';

import styles from './styles.module.scss';

interface Props {
  requestData?: APIRequestData;
}

const calculateTotalRequests = (data: number[]) => data.reduce((acc, el) => acc + el, 0);
const calculateErrorRate = ({ failure, success }) => {
  const calc = failure / success;
  // only show % up to 2 decimal places
  return calc >= 100 ? 100 : calc.toFixed(2);
};

const APIRequestGraph = ({ requestData }: Props) => {
  const bem = useClassy(styles, 'APIRequestGraph');

  if (!requestData || !requestData.data || isEmpty(requestData.data)) {
    return null;
  }

  const success = calculateTotalRequests(requestData.data[0]);
  const failure = calculateTotalRequests(requestData.data[1]);
  const errorRate = calculateErrorRate({ failure, success });

  return (
    <div className={bem('&')}>
      <header className={bem('-header')}>API Requests</header>
      <Sparkline className={bem('-graph')} data={requestData.data} labels={requestData.labels} maxWidth="230" />
      <Flex align="center" tag="div">
        <Flex gap={0} tag="span">
          <Flex align="center" gap={0} tag="span">
            <HTTPStatus iconOnly={true} status={200} />
            {abbreviatedNumber(success)}
          </Flex>
          <Flex align="center" gap={0} tag="span">
            <HTTPStatus iconOnly={true} status={500} />
            {abbreviatedNumber(failure)}
          </Flex>
        </Flex>
        <span>Error Rate: {errorRate}%</span>
      </Flex>
    </div>
  );
};

export default APIRequestGraph;
