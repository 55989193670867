import { useState, useEffect, useMemo } from 'react';

import { cleanupExternalTooltip } from '@core/utils/graphTooltip';

// md matching styling in https://github.com/readmeio/readme/blob/f2fb12e8ee13d959b7dd8106885cf55d3f8ed261/packages/react/src/ui/Metrics/Graph/style.module.scss#L145
const TOOLTIP_PADDING = 20;

function useGraphTooltip(chart, tooltip) {
  const [isVisible, setIsVisible] = useState(tooltip.opacity !== 0);

  function hideTooltip(e) {
    // Hide tooltip for all non-keyboard events & only for specific key events that could trigger a navigation change ('Enter') or just make sense ('Escape')
    if (e.code == null || e.code === 'Enter' || e.code === 'Escape') setIsVisible(false);
  }

  useEffect(() => {
    if (chart) setIsVisible(tooltip.opacity !== 0);

    return () => {
      // Cleanup tooltip div element on unmount of tooltip so element doesn't persist on page
      cleanupExternalTooltip();
    };
  }, [chart, tooltip.opacity]);

  useEffect(() => {
    // Hide tooltip when going back/forward or on specific keysdown events
    window.addEventListener('popstate', hideTooltip, false);
    window.addEventListener('keydown', hideTooltip, false);
    return () => {
      window.removeEventListener('popstate', hideTooltip);
      window.removeEventListener('keydown', hideTooltip);
    };
  }, []);

  const boundingRect = chart?.canvas?.getBoundingClientRect();

  // responsive positioning
  const offscreen = (tooltip?._eventPosition?.x || 0) + TOOLTIP_PADDING < boundingRect?.width;

  const y = (boundingRect?.y || 0) + window.scrollY;
  const x = (boundingRect?.x || 0) + window.scrollX;

  const style = useMemo(
    () => ({
      left: `${x + tooltip.caretX}px`,
      top: `${y + tooltip.caretY}px`,
      // positions left/right based on space
      transform: offscreen
        ? `translate(${TOOLTIP_PADDING}px, -${TOOLTIP_PADDING}px)`
        : `translate(calc(-100% - ${TOOLTIP_PADDING}px), -${TOOLTIP_PADDING}px)`,
      font: tooltip.options.bodyFont.string,
      padding: `${tooltip.padding}px ${tooltip.padding}px`,
    }),
    [tooltip.caretX, tooltip.caretY, tooltip.options.bodyFont.string, tooltip.padding, x, y, offscreen],
  );

  return { isVisible, style };
}

export default useGraphTooltip;
